import React, { useMemo, useRef, useState } from "react";
import { Light } from "three/src/lights/Light";
import { PointLightShadow } from "three/src/lights/PointLightShadow";
import { useFrame } from "@react-three/fiber";
import { Object3D } from "three";


const radius = 2;
export function BackgroundSceneLight({...props}: any) {
    const ref = useRef<Object3D>(null);
    const sinRef = useRef(props.position[0] < 0);

    const yAngle = useRef(Math.random() * 1);

    useFrame((state, delta, frame) => {
        if (!ref.current) return;
        const light = ref.current;
        // const { x } = light.position;
        // rotate the x position around the radius
        // const newX = x + Math.cos(radius);
        // light.position.setX(newX);
        const elapsedTime = state.clock.getElapsedTime()
        const angle = elapsedTime * 0.5;
        const fn = sinRef.current ? Math.sin : Math.cos;
        const x = fn(angle) * radius;
        light.position.setX(x);


        const y = Math.sin(yAngle.current) * radius;
        light.position.setY(y);

    });

    return (
        // @ts-ignore
        <pointLight ref={ref} {...props} />
    )
}
