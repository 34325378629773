import React from 'react';
import './App.scss';
import { BackgroundScene } from "./3D/Scenes/BackgroundScene/BackgroundScene";

function App() {
    return (
        <>
            <BackgroundScene />
            <div className="App">
                <h1>👉 Immersive Touch 👈</h1>
                <div>
                    <p>This is a placeholder for an epic website.</p>
                    <p>Maybe someday we'll have one...</p>
                </div>
            </div>
        </>
    );
}

export default App;
