import React, { useRef, useState } from 'react'
import "./BackgroundScene.scss";
import { Canvas, useFrame } from '@react-three/fiber'
import { Sphere } from "@react-three/drei";
import { MeshStandardMaterial } from "three";
import { DoubleSide, Side } from "three/src/constants";
import { BackgroundSceneLight } from "./Lights/BackgroundSceneLight/BackgroundSceneLight";

interface IProps {

}

export function BackgroundScene(props: IProps) {
    return (
        <div className={'background-scene'}>
            <Canvas camera={{ position: [0, 0, 0] }} >
                <ambientLight />
                <BackgroundSceneLight position={[-1, 0, 0]} intensity={25} color={'#ffc400'} />
                <BackgroundSceneLight position={[1, 0, 0]} intensity={25} color={'#8d00ff'} />
                <Sphere
                    material={new MeshStandardMaterial({ color: '#484848', side: DoubleSide })}
                    position={[0, 0, 0]} />
            </Canvas>
        </div>
    )
}
